import 'react-app-polyfill/ie11';
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import '../scss/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

Element.prototype._getBoundingClientRect = Element.prototype.getBoundingClientRect
Element.prototype.getBoundingClientRect = function () {
  const rect = Element.prototype._getBoundingClientRect.call(this)
  rect.x = rect.left
  rect.y = rect.top
  return rect
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
