import React from 'react';
import { Link } from 'react-router-dom';

import { slide as Menu } from 'react-burger-menu'
import Api from '../api/api';

import Episodes from './Episodes';

import CloseIcon from '../../assets/svg/close.svg';

const Overlay = (props) => {
    const [menuIsOpen, setMenuIsOpen] = React.useState(false)
    const [menuObject, setMenuObject] = React.useState({})
    const [episodeSection, setEpisodeSection] = React.useState('baldrian')
    const [episodesIsOpen, setEpisodesIsOpen] = React.useState(false)

    React.useEffect(() => {
        getMenuItems()
    }, [])

    const getMenuItems = async () => {
        const response = await Api.getArticleMenu()
        setMenuObject(response.data.items)
    }

    const handleGoBack = () => {
        setEpisodesIsOpen(false)
    }

    const onClickVideo = (item) => {
        setEpisodesIsOpen(false)
        setMenuIsOpen(false)

        props.history.push('/videoer/' + item.slug)
    }

    return (
        <Menu
            onStateChange={(state) => {
                setMenuIsOpen(state.isOpen)
                setEpisodesIsOpen(false)
            }}
            width={ '550px' }
            right={true}
            isOpen={menuIsOpen}>
            <nav className=" bm-item-list">
                {!episodesIsOpen && <div className="nav-close-icon" onClick={() => setMenuIsOpen(false)}>
                    <img src={ CloseIcon } />
                </div>}
                {
                    !episodesIsOpen && Object.keys(menuObject).map((itemKey, index) => {
                        const item = menuObject[itemKey];

                        // if (item.slug === 'vaerkstedet') {
                        //     return null
                        // }
                        
                        return (
                            <div className="wrapper" key={index}>
                                <div className="menu-item bm-item title" onClick={(event) => event.preventDefault()}>
                                    <span className="fake-link" dangerouslySetInnerHTML={{ __html: item.title}}>
                                    </span>
                                </div>
                                <ul>
                                    {
                                        item.slug.toLowerCase() === 'baldrian' &&
                                        <li>
                                            <div
                                                onClick={(event) => {
                                                    event.preventDefault()

                                                    setEpisodeSection('baldrian')
                                                    setEpisodesIsOpen(true)
                                                }}
                                                className="menu-item bm-item"
                                            >
                                                <span className="fake-link">
                                                    Episoder
                                                </span>
                                            </div>
                                        </li>
                                    }
                                    {
                                        item.slug.toLowerCase() === 'spilkarrusellen' &&
                                        <li>
                                            <div
                                                href="/"
                                                onClick={(event) => {
                                                    event.preventDefault()

                                                    setEpisodeSection('spilkarrusellen')
                                                    setEpisodesIsOpen(true)
                                                }}
                                                className="menu-item bm-item"
                                            > 
                                                <span className="fake-link">
                                                    Episoder
                                                </span>
                                            </div>
                                        </li>
                                    }
                                    {
                                        item.slug.toLowerCase() === 'vaerkstedet' &&
                                        <li>
                                            <div
                                                href="/"
                                                onClick={(event) => {
                                                    event.preventDefault()

                                                    setEpisodeSection('vaerkstedet')
                                                    setEpisodesIsOpen(true)
                                                }}
                                                className="menu-item bm-item"
                                            >
                                                <span className="fake-link">
                                                    Episoder
                                                </span>
                                            </div>
                                        </li>
                                    }
                                    {
                                        item.child_items && item.child_items.map((child, key) => {
                                            return (
                                                <li key={key}>
                                                    <a
                                                        onClick={() => setMenuIsOpen(false)}
                                                        className="menu-item bm-item"
                                                        href={'/anbefalinger/' + child.slug}
                                                        key={key}
                                                        dangerouslySetInnerHTML={{ __html: child.title}}
                                                    >
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
                {
                    <div className={"episodes-wrapper" + (episodesIsOpen ? ' show' : ' hidden')}>
                        <Episodes
                            overviewId={'video'}
                            section={episodeSection}
                            isShowing={episodesIsOpen}
                            goBack={handleGoBack}
                            onClickVideo={onClickVideo}
                        />
                    </div>
                }
            </nav>
        </Menu>
    )
}

export default Overlay;