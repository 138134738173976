import React, {useRef, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import { gsap } from "gsap";
import { Draggable } from 'gsap/all'
import { TweenMax, TimelineMax } from 'gsap/all';

import HousesImage from './HousesImage';
import LibraryImage from './LibraryImage';
import Carouselmage from './Carouselmage';
import BaldrianImage from './BaldrianImage';
import WorkshopImage from './WorkshopImage'

gsap.registerPlugin(Draggable);

const FrontpageSVG = (props) => {
    const container = useRef(null)
    const map = useRef(null)
    const history = useHistory();
    let libraryRef = useRef();
    let carouselRef = useRef();
    let baldrianRef = useRef();
    let workshopRef = useRef();
    
    useEffect(() => {
        Draggable.create(
            map.current, 
            {
                type:"x,y",
                edgeResistance:0.65,
                bounds: container.current,
                inertia:true,
                transformOrigin:"left"
            });

            var tl = new TimelineMax({ paused:true, yoyo:true, repeat: 0 });

            // set the initial scale and rotation to 0
            // and the transform-origin 50% 50%
            TweenMax.set(libraryRef.current, {
                scale: 0,
                roatation: 0,
                transformOrigin: "50% 50%"         // make transform origin be center for x and y axis
            });

            TweenMax.set(carouselRef.current, {
                opacity: 0 
            });

            TweenMax.set(baldrianRef.current, {
                opacity: 0
            });

            TweenMax.set(workshopRef.current, {
                opacity: 0
            });

            tl
                .to(libraryRef.current, .5, {scale:1})           // scale element to 100%
                .to(libraryRef.current, 1, {rotation:"+=360"})  // rotate element by a relative value of 360deg
                .to(carouselRef.current, .5, {opacity:1})
                .to(baldrianRef.current, .5, {opacity:1})
                .to(workshopRef.current, .5, {opacity:1})
            .play();

            if (libraryRef.current) {
                attachEventListener(libraryRef.current)
            }

            if (carouselRef.current) {
                attachEventListener(carouselRef.current)
            }

            if (baldrianRef.current) {
                attachEventListener(baldrianRef.current)
            }

            if (workshopRef.current) {
                attachEventListener(workshopRef.current)
            }
    })

    const attachEventListener = (element) => {
        element.addEventListener("mouseover", function( event ) {   
            var tl = new TimelineMax({ paused:true, yoyo:true, repeat: 0 });

            tl
                .to(element, .5, {scale:1.05})
                .to(element, 1, {scale:1})
                .play()

        }, false);
    }

    const onClickSection = (section) => {
        console.log('clicked')
        history.push('/' + section)
        
    }
    
    return (
            <div className="frontpage" id="container" ref={container}>
                <div className="frontpage__svg">
                    <svg
                        ref={map}
                        width="130vw"
                        height="100%"
                        viewBox="0 0 1036.484 768.007">
                            <g>
                                <HousesImage />
                            </g>
                            <g
                                transform="translate(586 95)"
                                id="f-library"
                                onClick={() => onClickSection('biblioteket')}
                                onTouchStart={() => onClickSection('biblioteket')}
                                ref={libraryRef}
                            >
                                <LibraryImage />
                            </g>

                            <g
                                transform="translate(330 210)"
                                id="f-carousel"
                                onClick={() => onClickSection('spilkarrusellen')}
                                onTouchStart={() => onClickSection('spilkarrusellen')}
                                ref={carouselRef}
                            >
                                <Carouselmage />
                            </g>

                            <g
                                transform="translate(157 414)"
                                id="f-baldrian"
                                onClick={() => onClickSection('baldrian')}
                                // onTouchStart={() => onClickSection('baldrian')}
                                ref={baldrianRef}
                            >
                                <BaldrianImage />
                            </g>
                            <g
                                transform="translate(690 444)"
                                id="f-workshop"
                                onClick={() => onClickSection('vaerkstedet')}
                                onTouchStart={() => onClickSection('vaerkstedet')}
                                ref={workshopRef}
                            >
                                <WorkshopImage />
                            </g>
                    
                    </svg>
                </div>
            </div>
    )
}

export default FrontpageSVG;