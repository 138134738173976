import React, { Component } from 'react'
import Header from './Header';
import Movies from './Movies';
import Api from '../api/api';
import BeatLoader from "react-spinners/BeatLoader";
import PostModal from './PostModal';


import BaldrianImage from '../../assets/baldrian.png'

export default class PostContainer extends Component {    
    ref = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            activeSection: 'baldrian',
            initialized: false,
            changeToSection: null,
            post: null,
        }
    }

    componentDidMount = () => {
        this.init()

        const { section } = this.props.match.params;
        if (section && section !== this.props.context.activeSection) {
            this.setState({ changeToSection: section })
            this.props.dispatch({ type: "set-section", payload: section })
        }
    }

    componentDidUpdate(prevPros) {
        if (this.state.changeToSection && this.ref.current) {
           this.setState({ changeToSection: null}, () => {
            this.ref.current.gotoSection(this.props.context.activeSection)
           })
        }

        if (prevPros.match.params.postId !== this.props.match.params.postId) {
            this.setState({initialized: false, post: null}, () => {
                this.init()
            })
        }
    }

    init = async () => {
        const slug = this.props.match.params.slug;

        console.log(this.props.match, 'this.props.matchthis.props.match')

        if (slug) {
            const response = await Api.getPostByUrl(slug)
            
            console.log(response, 'response')
            if (response.data) {
                this.setState({ post: response.data[0], initialized: true })
                let el =  document.getElementsByTagName('html')
                if (el) {
                    el[0].classList.add('article-open')
                }
            }
        }
    }

    setActiveSection = (activeSection) => {
        this.props.dispatch({ type: "set-section", payload: activeSection })
    }

    sectionHasChanged = (activeSection) => {
        if (this.ref.current) {
            this.props.dispatch({ type: "set-section", payload: activeSection })
            
        }
    }

    forceSection = (activeSection) => {
        this.props.history.push('/' + activeSection)
    }

    render() {
        const { activeSection } = this.props.context;

        if (!this.state.initialized) {
            return <div className="spinner-wrapper">
                <img src={BaldrianImage} className="baldrian-intro-loader" alt="intro-loader" />
                <div style={{marginTop: '80px'}}>
                    <BeatLoader
                        size={20}
                        color={"#793149"}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        }

        return (
            <React.Fragment>
                <div className="main">
                    <Header activeSection={this.props.context.activeSection} forceSection={this.forceSection} />
                    <div className={'main-content ' + (activeSection)}>
                        <div className="main-bg post-open active"></div>
                        <PostModal
                            postFetched={() => {
                                this.setState({ initialized: true })
                            }}
                            post={this.state.post}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
