import * as React from "react";

let ContextOne = React.createContext();

let initialState = {
  activeSection: 'baldrian',
  currentOpenVideo: null,
  currentOpenPost: null,
};

let reducer = (context, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "set-section":
      return { ...context, activeSection: action.payload };
    default:
      return context;
  }
};

function ContextOneProvider(props) {
  // [A]
  let [context, dispatch] = React.useReducer(reducer, initialState);
  let value = { context, dispatch };


  // [B]
  return (
    <ContextOne.Provider value={value}>{props.children}</ContextOne.Provider>
  );
}

let ContextOneConsumer = ContextOne.Consumer;

// [C]
export { ContextOne, ContextOneProvider, ContextOneConsumer };