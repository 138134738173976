import axios from 'axios'

// Api.
import { appConfig } from '../config';
const baseUrl = appConfig.api.baseUrl;

class Api {
    static getSections = async () => {
        const url = baseUrl + 'wp-json/wp/v2/section/';
        return await axios({
            method: 'get',
            url: url,
            config: {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }
            }
        })
    }

    static getPosts = async () => {
        const url = baseUrl + 'wp-json/wp/v2/posts/';
        // return await axios.get(url)
        return await axios({
            method: 'get',
            url: url,
            params: {
                per_page: 100,
            },
            config: {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }
            }
        })
    }

    static getVideos = async () => {
        const url = baseUrl + 'wp-json/wp/v2/videoer/';
        // return await axios.get(url)
        return await axios({
            method: 'get',
            url: url,
            params: {
                per_page: 100,
            },
            config: {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }
            }
        })
    }

    static getPost = async (postId) => {
        const url = baseUrl + 'wp-json/wp/v2/posts/' + postId;
        // return await axios.get(url)
        return await axios({
            method: 'get',
            url: url,
            config: {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }
            }
        })
    }

    static getPostByUrl = async (uri) => {
        const url = baseUrl + 'wp-json/wp/v2/posts?slug=' + uri;
        // return await axios.get(url)
        return await axios({
            method: 'get',
            url: url,
            config: {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }
            }
        })
    }

    static getVideoByUrl = async (uri) => {
        const url = baseUrl + 'wp-json/wp/v2/videoer?slug=' + uri;
        // return await axios.get(url)
        return await axios({
            method: 'get',
            url: url,
            config: {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }
            }
        })
    }

    static getVideo = async (videoId) => {
        const url = baseUrl + 'wp-json/wp/v2/videoer/' + videoId;
        // return await axios.get(url)
        return await axios({
            method: 'get',
            url: url,
            config: {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }
            }
        })
    }

    static getArticleMenu = async () => {
        const url = baseUrl + 'wp-json/menus/v1/menus/artikler';
        // return await axios.get(url)
        return await axios({
            method: 'get',
            url: url,
            config: {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }
            }
        })
    }
}

export default Api;