import React from 'react';

import FrontpageSVG from './FrontpageSVG'

import { TweenLite } from 'gsap/all';

const FrontPage = (props) => {
    React.useEffect(() => {
        // document.body.classList.add('is-frontpage');
        // screen.lockOrientationUniversal("landscape-primary")
        // window.screen.orientation.lock("landscape-primary")
        return () => {
            // document.body.classList.remove('is-frontpage');
        };
    }, [])

    return (
        <main className="frontpage-wrapper">
            <h1 style={{visibility: 'hidden'}} className="accessibility-heading">Lililib Forside</h1>
            <FrontpageSVG {...props}  role="main" />
        </main>
    )
}

export default FrontPage;