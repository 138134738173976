import React from 'react';
import ReactDOM from 'react-dom';

const Overlay = (props) => {
    const { isShowing, children, hide } = props;
    const theme = props.theme || 'dark';
    const node = React.useRef(null);

    const handleClickOutside = (e) => {
        if(node.current && node.current.contains(e.target)) {
            return;
        }
        
        // outside click
        props.hide && props.hide(false)
    };

    React.useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOutside);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return ReactDOM.createPortal(
        <React.Fragment>
        <div className={'overlay' + (isShowing ? ' show' : ' hide') + (' ' + theme) + (props.isArticle ? ' article' : '')} ref={node}>
            {/* <button onClick={hide}>Close</button> */}
            
                {children && children }
            
        </div>
        </React.Fragment>, document.getElementById('root')
    )
}

export default Overlay;