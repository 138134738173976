import React from 'react';
import ReactPlayer from 'react-player'
import { useHistory } from "react-router-dom";

const PostTeaser = (props) => {
    const { post } = props;
    let history = useHistory();
    
    const handleClickThumbnail = (post) => {
        history.push('/anbefalinger/' + post.slug)
    }

    let thumb = undefined;

    if (post.acf.video) {
        let video_id = post.acf.video.split('https://www.youtube.com/watch?v=')[1];

        if (!video_id) {
            // https://youtu.be/JJbgS48iLR8
            video_id = post.acf.video.split('https://youtu.be/')[1];
        }

        if (video_id) {
            thumb = video_id ? Youtube.thumb('http://www.youtube.com/watch?v=' + video_id) : undefined;
        }
    } else if (post.acf.image && !post.acf.video) {
        thumb = post.acf.image.sizes.thumbnail;
    }

    return (
        <React.Fragment>
            <div className={'post-container biblioteket'}>
                {
                    thumb &&
                    <div className="video-container">
                        <img src={thumb} alt={post.slug} className="react-player__preview" />
                    </div>
                }
            </div>
            <div className="item__text">
                <div className="item__text__title" dangerouslySetInnerHTML={{__html: post.title.rendered}}></div>
                <div className="item__text__button__wrapper">
                    <div
                        className='item__text__button artikler'
                    >
                        Læs mere
                    </div>
                </div>
            </div>
            <div
                className="video-container__overlay"
                onClick={() => handleClickThumbnail(post)}>
            </div>
        </React.Fragment>
    )
}

export default PostTeaser;

var Youtube = (function () {
    'use strict';

    var video, results;

    var getThumb = function (url, size) {
        if (url === null) {
            return '';
        }
        size    = (size === null) ? 'big' : size;
        results = url.match('[\\?&]v=([^&#]*)');
        video   = (results === null) ? url : results[1];

        if (size === 'small') {
            return 'http://img.youtube.com/vi/' + video + '/2.jpg';
        }
        return 'http://img.youtube.com/vi/' + video + '/0.jpg';
    };

    return {
        thumb: getThumb
    };
}());