import React, { Component } from 'react'

import Api from '../api/api';

import ExpandCollapse from 'react-expand-collapse';

import ArrowLeft from '../../assets/svg/arrow-left.svg'

export default class Episodes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: null,
            allVideos: [], 
            section: null,
            isFetching: false
        }
    }

    componentDidMount = async () => {
        const { overviewId, section } = this.props;

        const callback = overviewId === 'video' ? Api.getVideos : Api.getPosts;
        const response = await callback();
        let items = response.data;
        let sectionName = section;

        if (section === 'vaerkstedet') {
            sectionName = 'workshop'
        }

        if (section === 'spilkarrusellen') {
            sectionName = 'spilkarusellen'
        }


        if (overviewId === 'video') {
            items = items.filter(video => video.acf.section && video.acf.section.name && video.acf.section.name === sectionName)
        }

        this.setState({
            items,
            section,
            isFetching: false,
            allVideos: response.data,
        })
    }

    componentDidUpdate = (prevProps) => {
        const { overviewId, section } = this.props;

        let sectionName = section;

        if (section === 'vaerkstedet') {
            sectionName = 'workshop'
        }

        if (section === 'spilkarrusellen') {
            sectionName = 'spilkarusellen'
        }

        if (prevProps.section !== this.props.section && !this.state.isFetching) {
            this.setState({ isFetching: true }, async () => {
                let items = []

                if (overviewId === 'video') {
                    items = this.state.allVideos.filter(video => video.acf.section.name === sectionName)
                }

                this.setState({
                    items,
                    section,
                    isFetching: false
                })
            })
        }
        
    }

    getThumbnail = (link) => {
        let thumb = "";

        let video_id = link.split('https://www.youtube.com/watch?v=')[1];

        if (!video_id) {
            // https://youtu.be/JJbgS48iLR8
            video_id = link.split('https://youtu.be/')[1];
        }

        if (video_id) {
            thumb = video_id ? Youtube.thumb('http://www.youtube.com/watch?v=' + video_id) : undefined;
        }

        return thumb;
    }

    render() {
        const { items, isShowing } = this.state;

        if (this.state.isFetching) {
            return null;
        }

        let title = '';

        if (this.props.section === 'baldrian') {
            title = 'Baldrians Børnefortællinger';
        }

        if (this.props.section === 'spilkarrusellen') {
            title = 'Spilkarrusellen';
        }

        if (this.props.section === 'vaerkstedet') {
            title = 'Vaerkstedet';
        }

        return (
            <div className={'episodes' + (isShowing ? ' show' : '')}>
                <div className="episodes__title" onClick={() => this.props.goBack()}>
                    <span className="episodes__title__go-back"><img src={ ArrowLeft } /></span>
                    { title }
                </div>
                <div className="episodes__list">
                {
                    items && items.map((item, key) => {
                        return (
                            <div className="episodes__item" key={key} onClick={() => {
                            }}>
                                {
                                    item.type === 'videos' ?
                                    <div className="episodes__video" onClick={() => this.props.onClickVideo(item)}>
                                        <div className="video-container">
                                            <img src={this.getThumbnail(item.acf.youtube_link)} alt='thumb' className='react-player__preview' />
                                        </div>
                                    </div> : null
                                }
                                <div className="episodes__details">
                                    <div className="title" onClick={() => this.props.onClickVideo(item)}>
                                        <span dangerouslySetInnerHTML={{ __html: item.title.rendered}}></span>
                                    </div>
                                    <div className="secondary">
                                        <span>
                                            <ExpandCollapse
                                                previewHeight="84px"
                                                expandText='udvid'
                                                collapseText='luk'
                                            >
                                                <span onClick={() => this.props.onClickVideo(item)} dangerouslySetInnerHTML={{ __html: item.acf.Description}}>
                                                </span>
                                            </ExpandCollapse>
                                        </span>
                                        <strong className="bold">
                                            {/* 11.34 */}
                                        </strong>
                                    </div>
                                </div>
                                {/* {
                                    item.type === 'post' ?
                                    <PostTeaser post={item} /> : null
                                } */}
                            </div>
                        )
                    })
                }
                </div>
                
            </div>
        )
    }
}

var Youtube = (function () {
    'use strict';

    var video, results;

    var getThumb = function (url, size) {
        if (url === null) {
            return '';
        }
        size    = (size === null) ? 'big' : size;
        results = url.match('[\\?&]v=([^&#]*)');
        video   = (results === null) ? url : results[1];

        if (size === 'small') {
            return 'http://img.youtube.com/vi/' + video + '/2.jpg';
        }
        return 'http://img.youtube.com/vi/' + video + '/0.jpg';
    };

    return {
        thumb: getThumb
    };
}());