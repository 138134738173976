import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player'
import { useHistory, useParams } from "react-router-dom";
import Api from '../api/api';
import Overlay from './Overlay';
// import ArrowLeft from '../../assets/svg/arrow-left.svg'
import CloseImage from '../../assets/svg/close.svg'
import { ContextOne } from '../store/ContextOne'

const VideoModal = (props) => {
    let { dispatch } = React.useContext(ContextOne);
    const params = useParams()
    const history = useHistory()
    const [video, setvideo] = useState(null)
    const [videoId, setVideoId] = useState(null)
    const [isShowing, setIsShowing] = React.useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const _ref = React.useRef();

    const fetch = async (slug) => {
        const response = await Api.getVideoByUrl(slug)

        const video = response.data[0];

        setvideo(video)
        setIsShowing(true)
        setIsFetching(false)

        let video_id = video.acf.youtube_link.split('https://www.youtube.com/watch?v=')[1];

        if (!video_id) {
            // https://youtu.be/-Ls-atAIiVE
            video_id = video.acf.youtube_link.split('https://youtu.be/')[1];
        }

        setVideoId(video_id);

        dispatch({ type: "set-section", payload: video.acf.section.name })
    }

    useEffect(() => {
        const slug = params.slug;

        if (slug && !isFetching) {
            if (!video) {
                setIsFetching(true);
                fetch(slug)
            }
        }

        console.log(slug, 'slug')
        console.log(video && video.url, 'video.url')

        if (video && video.url && slug !== video.url && !isFetching) {
            setIsFetching(true);
            fetch(slug)
        }
    });

    const enterFullscreen = () => {
        if (_ref.current.requestFullscreen) {
          _ref.current.requestFullscreen();
        }
        else if (_ref.current.mozRequestFullScreen) {
          _ref.current.mozRequestFullScreen();
        }
        else if (_ref.current.webkitRequestFullScreen) {
          _ref.current.webkitRequestFullScreen();
        }
        else if (_ref.current.webkitEnterFullScreen) {
          _ref.current.webkitEnterFullScreen();
        }
        else if (_ref.current.msRequestFullscreen) {
          _ref.current.msRequestFullscreen();
        }
    }

    return ReactDOM.createPortal(
        <React.Fragment>
            {
                <Overlay isShowing={isShowing} theme={'dark'}>
                    {/* <div
                        className="fullscreen-trigger"
                        onClick={() => enterFullscreen()}
                    >
                        Klik for fuld skærm
                    </div> */}
                    <img
                        src={ CloseImage }
                        className="close-overlay"
                        onClick={() => {
                                console.log(video, 'video')
                                if (video.acf.section.slug === 'spilkarusellen') {
                                    history.push('/spilkarrusellen')
                                } else if (video.acf.section.slug === 'workshop') {
                                    history.push('/vaerkstedet')
                                } else {
                                    history.push('/' + video.acf.section.slug)
                                }
                                setIsShowing(false)
                            }
                        }
                    />
                    <div className="video-overlay">
                        <div className="video-overlay__wrapper">
                            <div className="video-container">
                                {
                                    video && isShowing &&
                                    <div ref={_ref}>
                                        <iframe
                                            id={videoId}
                                            title={videoId}
                                            width="560"
                                            height="315"
                                            src={"//www.youtube.com/embed/" + videoId + '?autoplay=1'}
                                            frameBorder="0"
                                            allowFullScreen="allowfullscreen"
                                        >
                                        </iframe>
                                    </div>
                                }
                            
                            </div>
                        </div>
                    </div>
                </Overlay>
            }
        </React.Fragment>, document.getElementById('root')
    )
}

export default VideoModal;