import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import ArrowLeft from '../../assets/svg/arrow-left.svg'

const PostModal = (props) => {
    console.log(props, 'propsprops')
    const history = useHistory()

    useEffect(() => {
        return (() => {
            let el =  document.getElementsByTagName('html')

            if (el) {
                    el[0].classList.remove('article-open')
            }
        })
    })

    return (
        <div className="post-overlay">
            <img src={ArrowLeft} className="arrow-left" onClick={() => {
                let el =  document.getElementsByTagName('html')

                if (el) {
                        el[0].classList.remove('article-open')
                }
                history.push('/biblioteket')
        
            }} />
            {
                props.post &&
                <div className="post-content">
                    <h1 dangerouslySetInnerHTML={{__html: props.post.title.rendered}}></h1>
                    <div dangerouslySetInnerHTML={{__html: props.post.content.rendered}}></div>
                </div>
            }
        </div>
    )
}

export default PostModal;