import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import Logo from '../../assets/svg/logo.png'

const Header = (props) => {
    const history = useHistory();
    const { activeSection, forceSection } = props;

    const onClickSection = (section) => {
        history.push(`/${section}`)
        forceSection(section)
    }

    return (
        <React.Fragment>
            <header className="header">
                <Link className="logo" to={'/'}>
                    <img src={Logo} alt="Lililib home" />
                </Link>
                <div
                    className={'header__section baldrian' + (activeSection === 'baldrian' ? ' active' : '')}
                    onClick={() => onClickSection('baldrian')}
                >
                </div>
                <div
                    className={'header__section spilkarrusellen' + (activeSection === 'spilkarrusellen' ? ' active' : '')}
                    onClick={() => onClickSection('spilkarrusellen')}
                >
                </div>
                <div
                    className={'header__section vaerkstedet' + (activeSection === 'vaerkstedet' ? ' active' : '')}
                    onClick={() => onClickSection('vaerkstedet')}
                ></div>
                <div
                    className={'header__section biblioteket' + (activeSection === 'biblioteket' ? ' active' : '')}
                    onClick={() => onClickSection('biblioteket')}
                >
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header;