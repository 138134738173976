// const localBaseUrl = 'http://localhost/lililib/';
// const localBaseUrl = 'http://api.lililib.dk/index.php/';
const prodBaseUrl = 'http://lililib.dk/index.php/';
const localBaseUrl = 'http://lililib.dk/index.php/';
// const prodBaseUrl = 'http://staging.lililib.dk/';

export const appConfig = {
    api: {
        baseUrl: process.env.NODE_ENV !== 'production' ? localBaseUrl : prodBaseUrl,
    },
}