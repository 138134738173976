import React from 'react';
import ReactPlayer from 'react-player';
import Overlay from './Overlay';
import CloseImage from '../../assets/svg/close.svg';
import axios from 'axios'
import fallbackImage from '../../assets/baldrian.png'
import Screenfull from 'screenfull-react';
import { useHistory } from 'react-router';

const VideoTeaser = (props) => {
    const history = useHistory();
    const { video } = props;
    const [isShowing, setIsShowing] = React.useState(false)
    const _ref = React.useRef();
    const [duration, setDuration] = React.useState(null)
    let video_id = video.acf.youtube_link.split('https://www.youtube.com/watch?v=')[1];

    if (!video_id) {
        // https://youtu.be/-Ls-atAIiVE
        video_id = video.acf.youtube_link.split('https://youtu.be/')[1];
    }

    const thumb = video_id ? Youtube.thumb('http://www.youtube.com/watch?v=' + video_id) : fallbackImage;

    const handleClickThumbnail = () => {
        history.push(`/videoer/${video.slug}`)
        // setIsShowing(true)
    }

    React.useEffect(() => {
        getDuration()
    }, [])

    const getDuration = async () => {
        const video_id = video.acf.youtube_link.split('https://www.youtube.com/watch?v=')[1];
        let url = 'https://www.googleapis.com/youtube/v3/videos?id=' + video_id + '&part=contentDetails&key=AIzaSyCMzeqaA4nwImpmBR0mpER1yAZkaaCz1UU'
        const response = await axios.get(url);

        let formattedDuration = response.data.items && response.data.items[0] ? convertYouTubeDuration(response.data.items[0].contentDetails.duration) : 0;
        setDuration(formattedDuration)
    }

    const convertYouTubeDuration = (yt_duration) => {
        const time_extractor = /([0-9]*H)?([0-9]*M)?([0-9]*S)?$/;
        const extracted = time_extractor.exec(yt_duration);
        const hours = parseInt(extracted[1], 10) || 0;
        const minutes = parseInt(extracted[2], 10) || 0;
        const seconds = parseInt(extracted[3], 10) || 0;

        return hours > 0 ? hours.toString().padStart(2, '0') + ':' : '' + 
            minutes.toString().padStart(2, '0') + ':' + 
            seconds.toString().padStart(2, '0');
    }

    const enterFullscreen = () => {
        // console.log(_ref.current, 'REF')
        if (_ref.current.requestFullscreen) {
          _ref.current.requestFullscreen();
        }
        else if (_ref.current.mozRequestFullScreen) {
          _ref.current.mozRequestFullScreen();
        }
        else if (_ref.current.webkitRequestFullScreen) {
          _ref.current.webkitRequestFullScreen();
        }
        else if (_ref.current.webkitEnterFullScreen) {
          _ref.current.webkitEnterFullScreen();
        }
        else if (_ref.current.msRequestFullscreen) {
          _ref.current.msRequestFullscreen();
        } else {
            console.log('ended here')
        }
    }

    return (
        <React.Fragment>
            <Screenfull />
            <div
                className={'video-container ' + (video.acf.section && video.acf.section.name ? video.acf.section.name : '')}
                onClick={() => handleClickThumbnail(video)}
            >
                <img src={thumb} alt="thumb" className="react-player__preview"/>
                {
                    duration &&
                    <span className="youtube-duration">
                        {duration}
                    </span>
                }
            </div>
            <div className="item__text">
                <div className="item__text__title" dangerouslySetInnerHTML={{__html: video.title.rendered}}></div>
                {video.acf.link && video.acf.label && <div className="item__text__button__wrapper">
                    <a
                        onClick={(e) => {
                            e.preventDefault()
                            window.location.href = video.acf.link;
                        }}
                        className={`item__text__button ${video.acf.section && video.acf.section.name ? video.acf.section.name : ''}`}
                        href={ video.acf.link }
                    >
                        { video.acf.label }
                    </a>
                </div>}
            </div>
            {/* <div
                className="video-container__overlay"
                onClick={() => handleClickThumbnail(video)}>
            </div> */}
            {isShowing && <Overlay isShowing={isShowing}>
                <img src={CloseImage} className="close-overlay" onClick={() => setIsShowing(false)}/>
                <div className="video-overlay">
                    <div className="video-overlay__wrapper">
                        <div className="video-container">
                            {
                                video && isShowing &&
                                <div ref={_ref}>
                                    <iframe
                                        id={video_id}
                                        title={video_id}
                                        width="560"
                                        height="315"
                                        frameBorder="0"
                                        allowFullScreen="allowfullscreen"
                                        src={"//www.youtube.com/embed/" + video_id + '?autoplay=1'}
                                    >
                                    </iframe>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Overlay>}
        </React.Fragment>
    )
}

export default VideoTeaser;

var Youtube = (function () {
    'use strict';

    var video, results;

    var getThumb = function (url, size) {
        if (url === null) {
            return '';
        }
        size    = (size === null) ? 'big' : size;
        results = url.match('[\\?&]v=([^&#]*)');
        video   = (results === null) ? url : results[1];

        if (size === 'small') {
            return 'http://img.youtube.com/vi/' + video + '/2.jpg';
        }
        return 'http://img.youtube.com/vi/' + video + '/0.jpg';
    };

    return {
        thumb: getThumb
    };
}());
