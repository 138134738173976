import React from 'react';
// import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'

import Menu from './components/Menu';
import FrontPage from './components/FrontPage'
import MainContainer from './components/MainContainer';
import PostContainer from './components/PostContainer';
// import PostModal from './components/PostModal';
import VideoModal from './components/VideoModal';
import GA4React, { useGA4React } from "ga-4-react";

const ga4react = new GA4React('G-PRZ9VSEN6X');

import { ContextOneProvider, ContextOneConsumer } from './store/ContextOne';

const Main = (props) => {
  return (
    <React.Fragment>
        <ContextOneConsumer>
          {value => {
            return (
              <React.Fragment>
                <Menu {...props} />
                <MainContainer {...props} {...value} />
              </React.Fragment>
            )
          }}
        </ContextOneConsumer>
    </React.Fragment>
  )
}

const FrontPageConsumer = (props) => {
  return (
    <React.Fragment>
        <ContextOneConsumer>
          {value => {
            return (
              <React.Fragment>
                <FrontPage {...props} {...value} />
              </React.Fragment>
            )
          }}
        </ContextOneConsumer>
    </React.Fragment>
  )
}

const PostConsumer = (props) => {
  return (
    <React.Fragment>
        <ContextOneConsumer>
          {value => {
            return (
              <React.Fragment>
                <Menu {...props} />
                <PostContainer {...props} {...value} />
              </React.Fragment>
            )
          }}
        </ContextOneConsumer>
    </React.Fragment>
  )
}

function App() {
  // const ga4React = useGA4React();
  ga4react.initialize();

  const sectionUrls = [
    '/baldrian',
    '/spilkarrusellen',
    '/vaerkstedet',
    '/biblioteket',
  ]

  return (
    <ContextOneProvider>
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={FrontPageConsumer} />
            <Route exact path={ sectionUrls } component={Main} />
            <Route path="/anbefalinger/:slug" component={PostConsumer} />
            <Route path="/videoer/:slug" component={VideoModal} />
            <Route>
              <h1>404 - NOT FOUND</h1>
            </Route>
          </Switch>
          
          
        </Router>
      </div>
    </ContextOneProvider>
  );
}

export default App;
