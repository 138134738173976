import React, { Component } from 'react'
import Header from './Header';
import Movies from './Movies';
import Api from '../api/api';
import BeatLoader from "react-spinners/BeatLoader";

import BaldrianImage from '../../assets/baldrian.png'

export default class MainContainer extends Component {
    taxonomies = [
        {
            "id": 2,
            "count": 2,
            "description": "",
            "link": "http://local.test/section/baldrian/",
            "name": "baldrian",
            "slug": "baldrian",
            "taxonomy": "section",
            "meta": [],
            "acf": [],
            "_links": {
                "self": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/section/2"
                    }
                ],
                "collection": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/section"
                    }
                ],
                "about": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/taxonomies/section"
                    }
                ],
                "wp:post_type": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/videos?section=2"
                    }
                ],
                "curies": [
                    {
                        "name": "wp",
                        "href": "https://api.w.org/{rel}",
                        "templated": true
                    }
                ]
            }
        },
        {
            "id": 3,
            "count": 1,
            "description": "",
            "link": "http://local.test/section/spilkarrusellen/",
            "name": "spilkarrusellen",
            "slug": "spilkarrusellen",
            "taxonomy": "section",
            "meta": [],
            "acf": [],
            "_links": {
                "self": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/section/3"
                    }
                ],
                "collection": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/section"
                    }
                ],
                "about": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/taxonomies/section"
                    }
                ],
                "wp:post_type": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/videos?section=3"
                    }
                ],
                "curies": [
                    {
                        "name": "wp",
                        "href": "https://api.w.org/{rel}",
                        "templated": true
                    }
                ]
            }
        },
        {
            "id": 5,
            "count": 0,
            "description": "",
            "link": "http://local.test/section/vaerkstedet/",
            "name": "vaerkstedet",
            "slug": "vaerkstedet",
            "taxonomy": "section",
            "meta": [],
            "acf": [],
            "_links": {
                "self": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/section/5"
                    }
                ],
                "collection": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/section"
                    }
                ],
                "about": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/taxonomies/section"
                    }
                ],
                "wp:post_type": [
                    {
                        "href": "http://local.test/wp-json/wp/v2/videos?section=5"
                    }
                ],
                "curies": [
                    {
                        "name": "wp",
                        "href": "https://api.w.org/{rel}",
                        "templated": true
                    }
                ]
            }
        }
    ]

    ref = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            activeSection: 'baldrian',
            initialized: false,
            videosSorted: {},
            baldrianOffset: 0,
            spilkarusellenOffset: 0,
            artikelerOffset: 0,
            allSlides: [],
            changeToSection: null
        }
    }

    componentDidMount = () => {
        this.init()
        const sections = [
            'baldrian',
            'spilkarrusellen',
            'vaerkstedet',
            'biblioteket',
        ]


        const { path } = this.props.match;
        const section = path.replace(/\\|\//g,'') 
        if (section && section !== this.props.context.activeSection && sections.indexOf(section) !== -1) {
            this.setState({ changeToSection: section })
            this.props.dispatch({ type: "set-section", payload: section })
        } else {
            this.setState({ changeToSection: section })
        }

        window.addEventListener("orientationchange", this.handleDirectionChange);
    }

    componentDidUpdate(prevPros) {
        if (this.state.changeToSection && this.ref.current) {
           this.setState({ changeToSection: null}, () => {
            this.ref.current.gotoSection(this.props.context.activeSection)
           })
        }
    }

    handleDirectionChange = () => {
        if  (this.ref.current) {
            this.ref.current.gotoSection(this.props.context.activeSection)
        }
    }

    init = async () => {
        const postsResponse = await Api.getPosts()
        const response = await Api.getVideos()
        let allSlides = []
        let videos = response.data;
        let allPosts = postsResponse.data;

        const posts = allPosts
            .filter(post => post.acf.show_on_frontpage)
            .map(post => {
                post.section = {
                    name: 'vaerkstedet'
                }
                return post;
            })
        
        let videosSorted = {
            'baldrian': [],
            'spilkarrusellen': [],
            'vaerkstedet': [],
            'biblioteket': [],
        };

        const vees = videos.map(v => v.acf.section)

        for (const i in videos) {
            videos[i]['section'] = this.taxonomies.find(taxonomy => videos[i].acf.section &&
                 taxonomy.id === videos[i].acf.section.term_id);

            if (videos[i]['section']) {
                if (!videosSorted[videos[i]['section'].name]) {
                    videosSorted[videos[i]['section'].name] = [];
                }

                videosSorted[videos[i]['section'].name].push(videos[i])
            }
        }

        videosSorted['biblioteket'] = posts;

        for (const i in videosSorted) {
            for (const key in videosSorted[i]) {
                allSlides.push(videosSorted[i][key])
            }
        }

        this.setState({
            // activeSection,
            posts,
            videosSorted,
            allSlides,
            initialized: true,
            spilkarusellenOffset: videosSorted['baldrian'].length - 1,
            workshopOffset: (videosSorted['baldrian'].length + videosSorted['spilkarrusellen'].length) - 1,
            artikelerOffset: (videosSorted['baldrian'].length + videosSorted['spilkarrusellen'].length + videosSorted['vaerkstedet'].length) - 1,
            
        }, () => {
            // this.forceSection(section)
        })
    }

    setActiveSection = (activeSection) => {
        this.props.dispatch({ type: "set-section", payload: activeSection })
    }

    sectionHasChanged = (activeSection) => {
        if (this.ref.current) {
            this.props.dispatch({ type: "set-section", payload: activeSection })
            
        }
    }

    forceSection = (activeSection) => {
        if (this.ref.current) {
            this.props.dispatch({ type: "set-section", payload: activeSection })
            // this.setState({ activeSection })
            this.ref.current.gotoSection(activeSection)
        }
    }

    onChangeSlideHandler = (index) => {
        if (this.state.allSlides[index + 1]) {
            const slide = this.state.allSlides[index + 1];

            if (slide) {
                const section = slide.section.name
    
                if (this.state.initialized && this.props.context.activeSection !== section) {
                    this.setActiveSection(section)
                }
            }
        }
    }

    render() {
        const { activeSection } = this.props.context;

        if (!this.state.initialized) {
            return <div className="spinner-wrapper">
                <img src={BaldrianImage} className="baldrian-intro-loader" alt="intro-loader" />
                <div style={{marginTop: '80px'}}>
                    <BeatLoader
                        size={20}
                        color={"#793149"}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        }

        return (
            <React.Fragment>
                <div className="main">
                    <h1 style={{visibility: 'hidden'}} className="accessibility-heading">Video oversigt</h1>
                    <Header activeSection={this.props.context.activeSection} forceSection={this.forceSection} />
                    <div className={'main-content ' + (activeSection)}>
                        <div className={"main-bg baldrian" + (this.props.context.activeSection === 'baldrian' ? ' active' : '')}></div>
                        <div className={"main-bg spilkarrusellen" + (this.props.context.activeSection === 'spilkarrusellen' ? ' active' : '')}></div>
                        <div className={"main-bg biblioteket" + (this.props.context.activeSection === 'biblioteket' ? ' active' : '')}></div>
                        <div className={"main-bg vaerkstedet" + (this.props.context.activeSection === 'vaerkstedet' ? ' active' : '')}></div>
                            <Movies
                                onChangeSlideHandler={this.onChangeSlideHandler}
                                ref={this.ref}
                                videosSorted={this.state.videosSorted}
                                allSlides={this.state.allSlides}
                                spilkarusellenOffset={this.state.spilkarusellenOffset}
                                artikelerOffset={this.state.artikelerOffset}
                                activeSection={this.props.context.activeSection}
                                workshopOffset={this.state.workshopOffset}
                                sectionHasChanged={this.sectionHasChanged}
                            />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
