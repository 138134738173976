import React, { Component } from 'react'

import { gsap } from "gsap";
import VisibilitySensor from 'react-visibility-sensor';

// Third party components.
import VideoTeaser from './VideoTeaser';
import PostTeaser from './PostTeaser'
import "react-multi-carousel/lib/styles.css";
import ArrowLeft from '../../assets/svg/arrow-left.svg'
// import { TweenLite } from 'gsap/all';
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)


export default class Movies extends Component {
    carousel = React.createRef()
    
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            posts: [],
            isShowing: false,
            video: null,
            videosSorted: {},
            baldrianOffset: 0,
            spilkarusellenOffset: 0,
            artikelerOffset: 0,
            workshopOffset: 0,
            initialized: false,
            swiper: null,
            refs: [],
            lock: false,
            activeIndex: 0,
            // fixedSort: ['baldrian', 'spilkarrusellen', 'biblioteket'],
            lastReached: false,
            firstReached: false,
            fixedSort: ['baldrian', 'spilkarrusellen', 'biblioteket', 'vaerkstedet'],
        };

        this.refs = [];
    }

    gotoSection = (section) => {
        let activeIndex = 0;

        this.setState({
            lock: true
        }, () => {
            if (section === 'baldrian') {
                let items = document.getElementsByClassName('item');
                let wrapper = document.getElementById('carousel')

                gsap.to(wrapper, {duration: .5, scrollTo: {x: 0}});
            }
    
            if (section === 'spilkarrusellen') {
                let items = document.getElementsByClassName('item');
                let wrapper = document.getElementById('carousel')
                let cords = items[this.props.spilkarusellenOffset].getBoundingClientRect();
                let body = document.body.getBoundingClientRect();

                if (body.width < 1025 && body.height > body.width) {
                    gsap.to(wrapper, {duration: .5, scrollTo: {x: Math.abs((wrapper.scrollLeft - cords.y) + document.body.getBoundingClientRect().height / 2)}});
                } else {
                    gsap.to(wrapper, {duration: .5, scrollTo: {x: Math.abs((wrapper.scrollLeft + cords.x) + (document.body.getBoundingClientRect().width / 3)) }});
                    // gsap.to(wrapper, {duration: .5, scrollTo: items[this.props.spilkarusellenOffset]});
                }

                activeIndex = this.props.spilkarusellenOffset;
            }
    
            if (section === 'biblioteket') {
                let items = document.getElementsByClassName('item');
                let wrapper = document.getElementById('carousel')
                let cords = items[this.props.artikelerOffset].getBoundingClientRect();
                let body = document.body.getBoundingClientRect();

                if (body.width < 1025 && body.height > body.width) {
                    gsap.to(wrapper, {duration: .5, scrollTo: {x: Math.abs((wrapper.scrollLeft - cords.y) + document.body.getBoundingClientRect().height / 2)}});
                } else {
                    // gsap.to(wrapper, {duration: .5, scrollTo: items[this.props.artikelerOffset]});
                    gsap.to(wrapper, {duration: .5, scrollTo: {x: Math.abs((wrapper.scrollLeft + cords.x) + (document.body.getBoundingClientRect().width / 3)) }});
                }

                activeIndex = this.props.artikelerOffset + 2 ;
            }

            if (section === 'vaerkstedet') {
                let items = document.getElementsByClassName('item');
                let wrapper = document.getElementById('carousel')
                let cords = items[this.props.workshopOffset].getBoundingClientRect();
                let body = document.body.getBoundingClientRect();

                if (body.width < 1025 && body.height > body.width) {
                    gsap.to(wrapper, {duration: .5, scrollTo: {x: Math.abs((wrapper.scrollLeft - cords.y) + document.body.getBoundingClientRect().height / 2)}});
                } else {
                    // gsap.to(wrapper, {duration: .5, scrollTo: items[this.props.artikelerOffset]});
                    gsap.to(wrapper, {duration: .5, scrollTo: {x: Math.abs((wrapper.scrollLeft + cords.x) + (document.body.getBoundingClientRect().width / 3)) }});
                }

                activeIndex = this.props.workshopOffset;
            }

            setTimeout(() => {
                this.setState({ lock: false, activeIndex })
            }, 1000) 
        })
    }

    onChange = (isVisible, video, isFirst, isLast, section, index) => {
        const sectionName = video.section.name

        if (isVisible) {    
            // this.setState({ activeIndex: index })

            if (this.state.lock) return;

            this.props.sectionHasChanged(sectionName)
        }
    }

    nextSlide = () => {
        let items = document.getElementsByClassName('item');
        let wrapper = document.getElementById('carousel')
        let activeIndex = this.state.activeIndex + 2;

        if (activeIndex >= this.props.allSlides.length - 1) {
            activeIndex = this.props.allSlides.length - 1
        } else {
        }

        gsap.to(wrapper, {duration: .5, scrollTo: items[activeIndex]});
        this.setState({ activeIndex })
    }

    previousSlide = () => {
        let items = document.getElementsByClassName('item');
        let wrapper = document.getElementById('carousel')
        let activeIndex = this.state.activeIndex - 2;

        if (activeIndex < 0) {
            activeIndex = 0;
        } else {

        }

        gsap.to(wrapper, {duration: .5, scrollTo: items[activeIndex]});
        this.setState({ activeIndex })
    }

    render() {
        // console.log(this.state.activeIndex, 'ACTIVE INDEX')
        return (
            <div className="carousel-wrapper" ref={this.carousel} id="carousel">
                    <div className="carousel-arrows">
                        <button
                            onClick={this.nextSlide}
                            className="custom-arrow right swiper-button-prev"
                        >
                            {/* <ArrowLeft title="move right" /> */}
                            <img src={ArrowLeft} />
                        </button>
                        <button
                            className="custom-arrow left swiper-button-next"
                            onClick={this.previousSlide}>
                                <img src={ArrowLeft} />
                                {/* <ArrowLeft title="move left" /> */}
                        </button>
                    </div>
                    {
                        
                        this.props.allSlides.map((item, key) => (
                            <VisibilitySensor
                                key={key}
                                onChange={(isVisible) => {
                                    // isVisible, video, isFirst, isLast, section, index
                                    this.onChange(
                                        isVisible, // isVisible
                                        item, // video
                                        (key < 3), // isFirst
                                        ((this.props.allSlides.length - 1 === key)), // isLast
                                        'baldrian', // sectionKey
                                        key // index
                                    )
                                }}>
                                <div
                                    className="item"
                                >
                                    
                                        {
                                            item.type === 'videos' ?
                                            <VideoTeaser video={item} /> : null
                                        }
                                        {
                                            item.type === 'post' ?
                                            <PostTeaser post={item} /> : null
                                        }
                                    
                                </div>
                            </VisibilitySensor>
                        ))
                    }
            </div>
        )
    }
}
